var YouLi = YouLi ? YouLi : {};
YouLi.Login = YouLi.Login ? YouLi.Login : {};

(function (YouLi) {
    (function (Login) {

        YouLi.Login.ReturnUrl = "";

        DoLogin = function (email, password, returnUrl, templateCode, partnerCode, selectedPlan, skipTripFind, token) {

            var isValid = $('#LoginForm').valid();

            if (!isValid)
                return;

            YouLi.Common.UI.ShowButtonLoading($("#LoginFormSubmit"));

            var headers = {};
            headers['__RequestVerificationToken'] = token;

            var data = {
                "email": encodeURIComponent(email),
                "password": password,
                "returnUrl": returnUrl,
                "templateCode": templateCode,
                "partnerCode": partnerCode,
                "selectedPlan": selectedPlan,
                "skipTripFind": skipTripFind
            };

            $.ajax({
                url: "/account/login",
                type: "POST",
                headers: headers,
                data: JSON.stringify(data),
                dataType: "json",
                contentType: "application/json",
                complete: function (response) {

                    if (response.status == 500 || response.statusText == "error") {
                        YouLi.Common.UI.HideButtonLoading($("#LoginFormSubmit"));
                        alert("We encountered an error");
                    }
                    else {
                        if (response.responseJSON.errorMessage) {
                            $(".login-error").html(response.responseJSON.errorMessage).fadeIn();
                            YouLi.Common.UI.HideButtonLoading($("#LoginFormSubmit"));
                        }
                        else if (response.responseJSON.redirect) {
                            window.location.href = response.responseJSON.redirect;
                        }
                        else {
                            $(".login-error").html("Some error occurred").fadeIn();
                            YouLi.Common.UI.HideButtonLoading($("#LoginFormSubmit"));
                        }
                    }
                }
            });
        };

        YouLi.Login.TogglePasswordShow = function (clickedElement) {
            var inputElement = clickedElement.closest(".form-group").find(".password-field");
            if (inputElement.attr("type") === "password") {
               inputElement.attr("type", "text");
            } else {
                inputElement.attr("type", "password");
            }
        };
        
        $(function () {

            $("#Email").focus();

            $(document).on("click", "#LoginFormSubmit", function (event) {

                event.preventDefault();

                $("Password").attr("type", "password"); // be sure to reset password type before submitting

                var email = $("#Email").val();
                var password = $("#Password").val();
                var returnUrl = $("#ReturnUrl").val();
                var templateCode = $("#TemplateCode").val();
                var partnerCode = $("#PartnerCode").val();
                var selectedPlan = $("#SelectedPlan").val();
                var skipTripFind = $("#SkipTripFind").val();
                var token = $('input[name="__RequestVerificationToken"]', '#LoginForm').val();

                DoLogin(email, password, returnUrl, templateCode, partnerCode, selectedPlan, skipTripFind, token);
            });

            $(document).on("click", ".password-show-link", function () {
                YouLi.Login.TogglePasswordShow($(this));
            });
        });

    }(YouLi.Login ? YouLi.Login : {}));
}(YouLi ? YouLi : {}));